import { render, staticRenderFns } from "./ProblemTree.vue?vue&type=template&id=01410515&scoped=true&"
import script from "./ProblemTree.vue?vue&type=script&lang=ts&"
export * from "./ProblemTree.vue?vue&type=script&lang=ts&"
import style0 from "./ProblemTree.vue?vue&type=style&index=0&id=01410515&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01410515",
  null
  
)

export default component.exports