import { render, staticRenderFns } from "./SGText.vue?vue&type=template&id=27521a06&scoped=true&"
import script from "./SGText.vue?vue&type=script&lang=ts&"
export * from "./SGText.vue?vue&type=script&lang=ts&"
import style0 from "./SGText.vue?vue&type=style&index=0&id=27521a06&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27521a06",
  null
  
)

export default component.exports